import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Page from '../components/Page'
import Container from '../components/Container'
import AddToCart from '../components/shop/AddToCart'
import formatCurrency from '../utils/formatCurrency'
import ShopLayout from '../layouts/Shop'
import styled from '@emotion/styled'

const PriceComment = styled.p`
  font-size: 12px;
  margin-top: -20px;
`

interface PageTemplateProps {
  data: {
    productsJson: {
      name: string
      amount: string
      slug: string
      price: number
      priceExact: boolean
      pfand: boolean
      producer: string
      category: string
      image?: {
        childImageSharp: {
          fixed: any
        }
      }
    }
    allCategoriesJson: {
      edges: Array<{
        node: {
          name: string
          slug: string
        }
      }>
    }
    allProducersJson: {
      edges: Array<{
        node: {
          name: string
          slug: string
        }
      }>
    }
  }
}

const ProductTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const product = data.productsJson
  const producers = new Map(data.allProducersJson.edges.map(i => [i.node.slug, i.node]))
  const producer = producers.get(product.producer)
  const categories = new Map(data.allCategoriesJson.edges.map(i => [i.node.slug, i.node]))
  const category = categories.get(product.category)

  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>
            {product.name} {!product.priceExact && <>ca. </>}
            {product.amount}
          </h1>
          {/* product.image && <Img fixed={product.image.childImageSharp.fixed} />*/}
          {producer && (
            <p>
              Produzent: <Link to={`/shop/produzent/${producer.slug}`}>{producer.name}</Link>
            </p>
          )}
          {category && (
            <p>
              Kategorie: <Link to={`/shop/kategorie/${category.slug}`}>{category.name}</Link>
            </p>
          )}
          <p>
            Preis: {!product.priceExact && <>ca. </>}
            {formatCurrency(product.price)}
            {product.pfand && <> + 0,30 € Pfand</>}
          </p>
          {!product.priceExact && <PriceComment>(der Preis wird mit dem tatsächlichen Gewicht neu berechnet)</PriceComment>}
          <AddToCart productSlug={product.slug} />
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default ProductTemplate

export const query = graphql`
  query ProductTemplateQuery($slug: String!) {
    productsJson(slug: { eq: $slug }) {
      name
      amount
      slug
      price
      priceExact
      pfand
      producer
      category
      image {
        childImageSharp {
          fluid(maxWidth: 100) {
            src
            aspectRatio
            srcSet
          }
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allCategoriesJson {
      edges {
        node {
          name
          slug
        }
      }
    }
    allProducersJson {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
