import * as React from 'react'
import CartContext from './CartContext'
import styled from '@emotion/styled'

const StyledInput = styled.input`
  width: 50px;
  margin-right: 10px;
`
interface IProps {
  productSlug: string
}
export default function AddToCart(props: IProps) {
  const cartContext = React.useContext(CartContext)
  const [amount, setAmount] = React.useState(1)
  return (
    <div>
      <StyledInput
        type="number"
        value={amount}
        onChange={e => {
          setAmount(parseInt(e.target.value, 10))
        }}
      />
      <button
        onClick={() => {
          cartContext.addToCart(props.productSlug, amount)
        }}
      >
        In den Warenkorb
      </button>
    </div>
  )
}
